import React from 'react'
import { HelmetTags } from '../../components/HelmetTags'
import { BanzCapital as BanzCapitalController } from '../../components/BanzCapital'

export const BanzCapital = (props) => {
  return (
    <>
      <HelmetTags page='banzCapital' />
      <BanzCapitalController {...props} />
    </>
  )
}
