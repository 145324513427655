import styled, { css } from 'styled-components'

export const HeaderContainer = styled.div`
  position: fixed;
  z-index: 1001;
  top: 0px;
  width: 100vw;
  height: 74px;
  box-sizing: border-box;
  padding: 0 15px;
  background-color: transparent;
  transition: transform 0.5s, background-color 0.25s, border 0.25s;
  display: flex;
  align-items: center;

  @media (min-width: 576px) {
    padding: 0 40px;
  }

  ${({ isShowHeader }) => isShowHeader && css`
    background-color: ${props => props.theme.colors.backgroundPage};
    border-bottom: 1px solid ${props => props.theme.colors.secondary};
  `}

  ${({ isMenuOpen }) => isMenuOpen && css`
    border-bottom: none;
  `}
`

export const HeaderLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  img {
    width: 70px;
  }
`
