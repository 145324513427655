import React from "react";
import { useTheme } from "styled-components";
import {
  BanzTechnologyContainer,
  InnerContainer,
  LogoWrapper,
  Description,
  LogoBlock,
} from "./styles";

export const BanzTechnology = (props) => {
  const theme = useTheme();

  return (
    <BanzTechnologyContainer bgimage={theme.images.technologyHero}>
      <InnerContainer>
        <LogoWrapper>
          <LogoBlock>
            <h1>Banz Technologies</h1>
            <img
              src={theme?.images?.logoTransparent}
              loading="lazy"
              alt="logo"
            />
          </LogoBlock>
        </LogoWrapper>
        <Description>
          <p>
            Banz Technologies provide computer platform and blockchain
            development solutions. This varies from construction of full project
            infrastructures to the design and implementation of blockchain
            architecture into existing created applications.
          </p>
        </Description>
      </InnerContainer>
    </BanzTechnologyContainer>
  );
};
