import styled from 'styled-components'

export const FooterContainer = styled.div`
  background-color: ${props => props.theme.colors.backgroundPage};
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const CopyRight = styled.p`
  color: ${props => props.theme.colors.white};
  font-weight: 300;
`