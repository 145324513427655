import React from 'react'
import { HelmetTags } from '../../components/HelmetTags'
import { BanzTechnology as BanzTechnologyController } from '../../components/BanzTechnology'

export const BanzTechnology = (props) => {
  return (
    <>
      <HelmetTags page='banzTechnology' />
      <BanzTechnologyController {...props} />
    </>
  )
}
