import React from 'react'
import {
  Switch,
  Route,
  useLocation
} from 'react-router-dom'
import { useOnlineStatus } from './hooks/useOnlineStatus'
import { ScrollToTop } from './components/ScrollToTop'
import { HelmetTags } from './components/HelmetTags'
import { NotNetworkConnectivity } from './components/NotNetworkConnectivity'
import { Header } from './components/Header'
import { Footer } from './components/Footer'
import { Home } from './pages/Home'
import { BanzCapital } from './pages/BanzCapital'
import { BanzTechnology } from './pages/BanzTechnology'
import { CapitalAbout } from './pages/CapitalAbout'
import { CapitalContact } from './pages/CapitalContact'
import { TechnologyAbout } from './pages/TechnologyAbout'
import { TechnologyContact } from './pages/TechnologyContact'

export const App = () => {
  const onlineStatus = useOnlineStatus()
  const location = useLocation()

  return (
    <>
      {location.pathname !== '/' && (
        <Header />
      )}
      <NotNetworkConnectivity />
      {onlineStatus && (
        <ScrollToTop>
          <HelmetTags />
          <Switch>
            <Route exact path='/'>
              <Home />
            </Route>
            <Route exact path='/capital'>
              <BanzCapital />
            </Route>
            <Route exact path='/capital/about'>
              <CapitalAbout />
            </Route>
            <Route exact path='/capital/contact'>
              <CapitalContact />
            </Route>
            <Route exact path='/technology'>
              <BanzTechnology />
            </Route>
            <Route exact path='/technology/about'>
              <TechnologyAbout />
            </Route>
            <Route exact path='/technology/contact'>
              <TechnologyContact />
            </Route>
          </Switch>
        </ScrollToTop>
      )}
      {location.pathname !== '/' && (
        <Footer />
      )}
    </>
  )
}