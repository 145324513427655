import React from "react";
import { useTheme } from "styled-components";
import {
  TechnologyContactContainer,
  InnerHeaderContainer,
  InnerContentWrapper,
  InnerContent,
} from "./styles";

export const TechnologyContact = (props) => {
  const theme = useTheme();

  return (
    <TechnologyContactContainer>
      <InnerHeaderContainer bgimage={theme.images.technologyHero}>
        <h1>Contact</h1>
      </InnerHeaderContainer>
      <InnerContentWrapper>
        <InnerContent>
          <p>For all development and consultancy inquiries, contact:</p>
          <p>tech@banz.capital</p>
        </InnerContent>
      </InnerContentWrapper>
    </TechnologyContactContainer>
  );
};
