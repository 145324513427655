import styled, { css } from "styled-components";

export const IconContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  z-index: 2001;
  > div {
    height: 2px;
    background: ${(props) => props.theme.colors.white};
    transition: top 0.1s ease-in-out, transform 0.1s ease-in-out,
      width 0.2s ease-in-out !important;
  }
  .top-bar {
    width: 25px;
    margin-bottom: 5px;
    transform: translateY(2px) rotate(16deg);
  }
  .middle-bar {
    width: 25px;
    margin-bottom: 10px;
    transform: translateY(2px) rotate(-16deg);
  }
  .bottom-bar {
    width: 25px;
    transform: translateY(5px) rotate(0deg);
    animation: blinker 1.08s linear infinite;
  }

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }

  ${({ isMeunOpen }) =>
    isMeunOpen &&
    css`
      .top-bar {
        width: 30px;
        margin-bottom: 5px;
        transform: translateY(7px) rotate(45deg);
      }
      .middle-bar {
        opacity: 0;
        margin-bottom: 5px;
      }
      .bottom-bar {
        transform: translateY(-7px) rotate(-45deg);
        width: 30px;
        animation: none;
      }
    `}
`;

export const SidebarMenuContainer = styled.div`
  position: fixed;
  z-index: 1001;
  transition: opacity 0.14s 0s ease-in-out, visibility 0s 0s linear;
  padding: 40px;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  visibility: visible;
  opacity: 1;
  background-color: rgba(21, 26, 35, 0.71);

  @media (min-width: 576px) {
    padding: 40px 100px;
  }

  ${({ isMeunOpen }) =>
    !isMeunOpen &&
    css`
      visibility: hidden;
      opacity: 0;
    `}
`;

export const SidebarMenuContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 100px 0;
  box-sizing: border-box;
  transition: opacity 0.14s 0.14s ease-in-out, visibility 0s 0s linear,
    transform 0.14s 0.18s ease-in-out;
  transform: scale(1, 1);
  visibility: visible;
  opacity: 1;

  ${({ isMeunOpen }) =>
    !isMeunOpen &&
    css`
      transform: scale(1.05, 1.05);
      visibility: hidden;
      opacity: 0;
    `}
`;

export const MenuLink = styled.div`
  cursor: pointer;
  padding: 8px 0;
  color: ${(props) => props.theme.colors.white};
  font-size: 20px;
  text-transform: uppercase;
  &:hover {
    color: ${(props) => props.theme.colors.secondary};
    a {
      color: ${(props) => props.theme.colors.secondary};
    }
  }

  a {
    color: ${(props) => props.theme.colors.white};
    text-decoration: none;
  }

  ${({ active }) =>
    active &&
    css`
      a {
        color: ${(props) => props.theme.colors.secondary};
      }
    `}
`;
