import styled, { css } from 'styled-components'
import React from 'react'

export const TechnologyAboutContainer = styled.div``

export const InnerHeaderContainerStyled = styled.div`
  max-height: 250px;
  box-sizing: border-box;
  padding: 60px 0;
  ${({ bgimage }) => bgimage && css`
    background-repeat: no-repeat, repeat;
    background-size: cover;
    object-fit: cover;
    background-position: center;
  `}

  > h1 {
    font-weight: 300;
    font-size: 48px;
    line-height: 1.6em;
    letter-spacing: .24em;
    text-transform: uppercase;
    text-align: center;
    color: ${props => props.theme.colors.white};
  }
`

export const InnerHeaderContainer = (props) => {
  const style = {}
  if (props.bgimage && !props.isClosed) {
    style.backgroundImage = `url(${props.bgimage})`
  } else {
    style.backgroundImage = `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${props.bgimage})`
  }

  return (
    <InnerHeaderContainerStyled {...props} style={style}>
      {props.children}
    </InnerHeaderContainerStyled>
  )
}

export const InnerContentWrapper = styled.div`
  background-color: ${props => props.theme.colors.backgroundPage};
  padding: 70px 15px;

  @media (min-width: 576px) {
    padding: 70px 34px;
  }
`

export const InnerContent = styled.div`
  width: 100%;
  margin: auto;

  @media (min-width: 576px) {
    width: 60%;
  }

  > p {
    text-align: center;
    font-weight: 300;
    font-style: normal;
    font-size: 15px;
    letter-spacing: .075em;
    color: ${props => props.theme.colors.white};
    margin-bottom: 15px;
  }
`
