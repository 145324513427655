import React from "react";
import { useTheme } from "styled-components";
import {
  CapitalContactContainer,
  InnerHeaderContainer,
  InnerContentWrapper,
  InnerContent,
} from "./styles";

export const CapitalContact = (props) => {
  const theme = useTheme();

  return (
    <CapitalContactContainer>
      <InnerHeaderContainer bgimage={theme.images.capitalHero}>
        <h1>Contact</h1>
      </InnerHeaderContainer>
      <InnerContentWrapper>
        <InnerContent>
          <p>
            For investment, media, and general inquiries, please contact us at
            the following email:
          </p>
          <p>info@banz.capital</p>
          <p>+1(339)244-3887</p>
        </InnerContent>
      </InnerContentWrapper>
    </CapitalContactContainer>
  );
};
