import React from "react";
import { useHistory } from "react-router-dom";

import {
  IconContainer,
  SidebarMenuContainer,
  SidebarMenuContent,
  MenuLink,
} from "./styles";

export const SidebarMenu = (props) => {
  const { isMenuOpen, handleClickMenuIcon } = props;

  const history = useHistory();

  const handleGoToPage = (path) => {
    history.push(path);
    handleClickMenuIcon(false);
  };

  return (
    <>
      <IconContainer
        onClick={() => handleClickMenuIcon(!isMenuOpen)}
        isMeunOpen={isMenuOpen}
      >
        <div className="top-bar" />
        <div className="middle-bar" />
        <div className="bottom-bar" />
      </IconContainer>
      <SidebarMenuContainer isMeunOpen={isMenuOpen}>
        {window.location.pathname.includes("capital") ? (
          <SidebarMenuContent isMeunOpen={isMenuOpen}>
            <MenuLink
              active={window.location.pathname === "/capital"}
              onClick={() => handleGoToPage("/capital")}
            >
              Home
            </MenuLink>
            <MenuLink
              active={window.location.pathname === "/capital/about"}
              onClick={() => handleGoToPage("/capital/about")}
            >
              About
            </MenuLink>
            <MenuLink>
              <a
                href="https://medium.com/banz-capital"
                target="_blank"
                rel="noreferrer"
              >
                Blog
              </a>
            </MenuLink>
            <MenuLink
              active={window.location.pathname === "/captial/contact"}
              onClick={() => handleGoToPage("/capital/contact")}
            >
              Contact
            </MenuLink>
            <MenuLink
              active={window.location.pathname === "/technology"}
              onClick={() => handleGoToPage("/technology")}
            >
              Banz technologies
            </MenuLink>
          </SidebarMenuContent>
        ) : (
          <SidebarMenuContent isMeunOpen={isMenuOpen}>
            <MenuLink
              active={window.location.pathname === "/technology"}
              onClick={() => handleGoToPage("/technology")}
            >
              Home
            </MenuLink>
            <MenuLink
              active={window.location.pathname === "/technology/about"}
              onClick={() => handleGoToPage("/technology/about")}
            >
              About
            </MenuLink>
            {/* <MenuLink>
                <a href='https://www.blocktower.com/competition' target='_blank' rel='noreferrer'>Blog</a>
              </MenuLink> */}
            <MenuLink
              active={window.location.pathname === "/technology/contact"}
              onClick={() => handleGoToPage("/technology/contact")}
            >
              Contact
            </MenuLink>
            <MenuLink
              active={window.location.pathname === "/capital"}
              onClick={() => handleGoToPage("/capital")}
            >
              Banz capital
            </MenuLink>
          </SidebarMenuContent>
        )}
      </SidebarMenuContainer>
    </>
  );
};
