import React from 'react'
import { HelmetTags } from '../../components/HelmetTags'
import { TechnologyAbout as TechnologyAboutController } from '../../components/TechnologyAbout'

export const TechnologyAbout = (props) => {
  return (
    <>
      <HelmetTags page='technology_about' />
      <TechnologyAboutController {...props} />
    </>
  )
}
