import React from "react";
import { useTheme } from "styled-components";
import {
  CapitalAboutContainer,
  InnerHeaderContainer,
  InnerContentWrapper,
  InnerContent,
} from "./styles";

export const CapitalAbout = (props) => {
  const theme = useTheme();

  return (
    <CapitalAboutContainer>
      <InnerHeaderContainer bgimage={theme.images.capitalHero}>
        <h1>About</h1>
      </InnerHeaderContainer>
      <InnerContentWrapper>
        <InnerContent>
          <p>
            As a cryptocurrency & blockchain investment fund, Banz Capital is
            your strategic approach to risk-adjusted, active management of your
            investments in an alternative market sector. Our firm uses a
            combination of discretionary & systematic (Algorithmic) strategies
            to deliver active management of investments for investors.
          </p>
        </InnerContent>
      </InnerContentWrapper>
    </CapitalAboutContainer>
  );
};
