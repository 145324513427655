import React from 'react'
import {
  FooterContainer,
  CopyRight
} from './styles'

export const Footer = (props) => {
  return (
    <FooterContainer>
      <CopyRight>Copyright © 2022 Banz LLC. All rights reserved.</CopyRight>
    </FooterContainer>
  )
}