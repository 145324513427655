import styled from 'styled-components'

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  img {
    width: 300px;

    @media (min-width: 576px) {
      width: 400px;
    }
  }
`

export const MainOptionsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 100px;

  @media (min-width: 576px) {
    flex-direction: row;
  }
`

export const OptionWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  padding: 15px;

  img {
    width: 100%;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      transform: scale(1.03);
    }

    @media (min-width: 1200px) {
      width: 80%;
    }
  }
`

export const HomeContainer = styled.div`
  min-height: 100vh;
  box-sizing: border-box;
  padding: 30px 15px 50px;
  background: linear-gradient(#171717, #030303);

  @media (min-width: 576px) {
    padding: 30px;
  }

  > h1 {
    font-weight: 300;
    font-size: 48px;
    line-height: 1.6em;
    letter-spacing: .24em;
    text-transform: uppercase;
    text-align: center;
    color: ${props => props.theme.colors.white};
  }
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 480px;
  margin: 80px auto 0px auto;

  > p {
    font-size: 16px;
    color: #8d8d8d;
    margin: 0px;
    letter-spacing: 8px;
  }
  > span {
    font-size: 18px;
    color: #8d8d8d;
  }

  @media (min-width: 576px) {
    flex-direction: row;
  }

  @media (min-width: 768px) {
    > p {
      font-size: 18px;
      letter-spacing: 10px;
    }
  }
`
