import React from "react";
import { useTheme } from "styled-components";
import {
  TechnologyAboutContainer,
  InnerHeaderContainer,
  InnerContentWrapper,
  InnerContent,
} from "./styles";

export const TechnologyAbout = (props) => {
  const theme = useTheme();

  return (
    <TechnologyAboutContainer>
      <InnerHeaderContainer bgimage={theme.images.technologyHero}>
        <h1>About</h1>
      </InnerHeaderContainer>
      <InnerContentWrapper>
        <InnerContent>
          <p>
            Banz Technologies provide blockchain development solutions. This
            varies from construction of full project infrastructures to the
            design and implementation of blockchain architecture into existing
            created applications.
          </p>
        </InnerContent>
      </InnerContentWrapper>
    </TechnologyAboutContainer>
  );
};
