import React, { /*useEffect*/ useState } from "react";
import { SidebarMenu } from "../SidebarMenu";
import { HeaderContainer, HeaderLogoWrapper } from "./styles";
import { useTheme } from 'styled-components'

export const Header = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isShowHeader /*, setIsShowHeader*/] = useState(false);
  const theme = useTheme();

  // const handleScroll = () => {
  //   if (window.scrollY > 70) setIsShowHeader(true)
  //   else setIsShowHeader(false)
  // }

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll)
  //   return () => window.removeEventListener('scroll', handleScroll)
  // }, [])

  return (
    <HeaderContainer isMenuOpen={isMenuOpen} isShowHeader={isShowHeader}>
      <SidebarMenu
        isMenuOpen={isMenuOpen}
        handleClickMenuIcon={setIsMenuOpen}
      />
      <HeaderLogoWrapper>
        <img src={theme?.images?.logoTransparent} alt='HEADER LOGO' loading='lazy' />
      </HeaderLogoWrapper>
    </HeaderContainer>
  );
};
