import React from 'react'
import { HelmetTags } from '../../components/HelmetTags'
import { CapitalContact as CapitalContactController } from '../../components/CapitalContact'

export const CapitalContact = (props) => {
  return (
    <>
      <HelmetTags page='capital_contact' />
      <CapitalContactController {...props} />
    </>
  )
}
