import React from 'react'
import { HelmetTags } from '../../components/HelmetTags'
import { CapitalAbout as CapitalAboutController } from '../../components/CapitalAbout'

export const CapitalAbout = (props) => {
  return (
    <>
      <HelmetTags page='capital_about' />
      <CapitalAboutController {...props} />
    </>
  )
}
