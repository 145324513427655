import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTheme } from 'styled-components'

import {
  HomeContainer,
  LogoWrapper,
  MainOptionsContainer,
  OptionWrapper,
  TextWrapper
} from './styles'

export const Home = (props) => {
  const theme = useTheme()
  const history = useHistory()

  const handleGoToPage = (path) => {
    history.push(path)
  }

  return (
    <HomeContainer>
      <LogoWrapper>
        <img src={theme?.images?.logoTransparent} loading='lazy' alt='logo' />
      </LogoWrapper>
      <MainOptionsContainer>
        <OptionWrapper>
          <img
            src={theme.images.capitalLogo}
            onClick={() => handleGoToPage('/capital')}
            alt='CAPITAL'
          />
        </OptionWrapper>
        <OptionWrapper>
          <img
            src={theme.images.technologyLogo}
            onClick={() => handleGoToPage('/technology')}
            alt='TECHNOLOGY'
          />
        </OptionWrapper>
      </MainOptionsContainer>
      <TextWrapper>
        <p>MIAMI</p>
        <span>•</span>
        <p className='last'>NEW YORK CITY</p>
      </TextWrapper>
    </HomeContainer>
  )
}