import React from "react";
import { useTheme } from "styled-components";
import {
  BanzCapitalContainer,
  InnerContainer,
  LogoWrapper,
  Description,
  LogoBlock,
} from "./styles";

export const BanzCapital = (props) => {
  const theme = useTheme();

  return (
    <BanzCapitalContainer bgimage={theme.images.capitalHero}>
      <InnerContainer>
        <LogoWrapper>
          <LogoBlock>
            <h1>Banz Capital</h1>
          </LogoBlock>
        </LogoWrapper>
        <Description>
          <p>
            Banz Capital is a multi-strategy investment fund that engages in the
            cryptocurrency & alternative digital asset markets.
          </p>
        </Description>
      </InnerContainer>
    </BanzCapitalContainer>
  );
};
