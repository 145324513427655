import React from 'react'
import styled, { css } from 'styled-components'

export const BanzCapitalContainerStyled = styled.div`
  min-height: 100vh;
  box-sizing: border-box;
  padding: 200px 0;
  ${({ bgimage }) => bgimage && css`
    background-repeat: no-repeat, repeat;
    background-size: cover;
    object-fit: cover;
    background-position: center;
  `}
`

export const BanzCapitalContainer = (props) => {
  const style = {}
  if (props.bgimage && !props.isClosed) {
    style.backgroundImage = `url(${props.bgimage})`
  } else {
    style.backgroundImage = `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${props.bgimage})`
  }

  return (
    <BanzCapitalContainerStyled {...props} style={style}>
      {props.children}
    </BanzCapitalContainerStyled>
  )
}

export const InnerContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 15px;
  box-sizing: border-box;

  @media (min-width: 576px) {
    width: 50%;
  }
`

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`

export const LogoBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    white-space: nowrap;
    margin-right: 0 15px 0 0;
    font-size: 45px;
    font-weight: 300;
    color: ${props => props.theme.colors.white};

    @media (min-width: 576px) {
      font-size: 50px;
    }
  }
`

export const Description = styled.div`
  display: flex;
  justify-content: flex-end;
  color: ${props => props.theme.colors.white};

  p {
    width: 100%;
    margin: 0;
    font-weight: 300;

    @media (min-width: 576px) {
      width: 80%;
    }
  }
`
