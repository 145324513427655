import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from './router'
import { ThemeProvider } from './contexts/ThemeContext'
import theme from './theme.json'

/**
 * Theme images
 */
import logoTransparent from './assets/images/logo-transparent.png'
import logoBlack from './assets/images/logo-black.png'
import notNetwork from './assets/images/not-network.svg'
import capitalHero from './assets/images/capital-hero.jpg'
import technologyLogo from './assets/images/BANZTECH.png'
import capitalLogo from './assets/images/BNZCAP.png'
import openMenu from './assets/images/open-icon.png'
import subHero from './assets/images/sub-hero.jpg'
import technologyHero from './assets/images/technology-hero.png'

theme.images = {
  logoTransparent,
  logoBlack,
  notNetwork,
  capitalHero,
  technologyLogo,
  capitalLogo,
  openMenu,
  subHero,
  technologyHero
}

const RouteApp = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router />
    </ThemeProvider>
  )
}

const wrapper = document.getElementById('root')
ReactDOM.render(<RouteApp />, wrapper)